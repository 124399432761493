import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import PaginaMensagem from './PaginaMensagem';
import Topo from './topo.jpg'

const UFS = [
	{ sigla: '', nome: 'Selecione' },
	{ sigla: 'AC', nome: 'Acre' },
	{ sigla: 'AL', nome: 'Alagoas' },
	{ sigla: 'AP', nome: 'Amapá' },
	{ sigla: 'AM', nome: 'Amazonas' },
	{ sigla: 'BA', nome: 'Bahia' },
	{ sigla: 'CE', nome: 'Ceará' },
	{ sigla: 'DF', nome: 'Distrito Federal' },
	{ sigla: 'ES', nome: 'Espírito Santo' },
	{ sigla: 'GO', nome: 'Goiás' },
	{ sigla: 'MA', nome: 'Maranhão' },
	{ sigla: 'MT', nome: 'Mato Grosso' },
	{ sigla: 'MS', nome: 'Mato Grosso do Sul' },
	{ sigla: 'MG', nome: 'Minas Gerais' },
	{ sigla: 'PA', nome: 'Pará' },
	{ sigla: 'PB', nome: 'Paraíba' },
	{ sigla: 'PR', nome: 'Paraná' },
	{ sigla: 'PE', nome: 'Pernambuco' },
	{ sigla: 'PI', nome: 'Piauí' },
	{ sigla: 'RJ', nome: 'Rio de Janeiro' },
	{ sigla: 'RN', nome: 'Rio Grande do Norte' },
	{ sigla: 'RS', nome: 'Rio Grande do Sul' },
	{ sigla: 'RO', nome: 'Rondônia' },
	{ sigla: 'RR', nome: 'Roraima' },
	{ sigla: 'SC', nome: 'Santa Catarina' },
	{ sigla: 'SP', nome: 'São Paulo' },
	{ sigla: 'SE', nome: 'Sergipe' },
	{ sigla: 'TO', nome: 'Tocantins' },
];

const FIELD_NAMES = {
	VIAJANTE_NOME_COMPLETO: 'nome',
	VIAJANTE_ENDERECO: 'endereco',
	VIAJANTE_CIDADE: 'cidade',
	VIAJANTE_ESTADO: 'estado',
	VIAJANTE_CEP: 'cep',
	VIAJANTE_DT_NASCIMENTO: 'data_nascimento',
	VIAJANTE_CPF: 'cpf',
	VIAJANTE_RG: 'rg',
	VIAJANTE_NR_TELEFONE_CELULAR: 'telefone',
	VIAJANTE_EMAIL: 'email',
	VIAJANTE_POSSUI_ALERGIA_RESTRICAO_MEDICA_ALIMENTAR: 'possue_alergia',
	VIAJANTE_POSSUI_ALERGIA_RESTRICAO_MEDICA_ALIMENTAR_DESCRICAO: 'especifice_alergia',
	ACOMPANHANTE_NOME_COMPLETO: 'acompanhante_nome',
	ACOMPANHANTE_DT_NASCIMENTO: 'acompanhante_data_nascimento',
	ACOMPANHANTE_CPF: 'acompanhante_cpf',
	ACOMPANHANTE_RG: 'acompanhante_rg',
	ACOMPANHANTE_POSSUI_ALERGIA_RESTRICAO_MEDICA_ALIMENTAR: 'acompanhante_possue_alergia',
	ACOMPANHANTE_POSSUI_ALERGIA_RESTRICAO_MEDICA_ALIMENTAR_DESCRICAO: 'acompanhante_especifice_alergia',
	APARTAMENTO_CAMA: 'configuracao_apartamento',
	VIAGEM_ESTADO_ORIGEM: 'estado_origem',
	VIAGEM_CIDADE_ORIGEM: 'cidade_origem',
	VIAGEM_DESTINO: 'destino_escolhido',
	VIAGEM_DT_PREFERENCIA: 'data_preferencia',
	VIAGEM_DT_ALTERNATIVA_1: 'data_alternativa1',
	VIAGEM_DT_ALTERNATIVA_2: 'data_alternativa2',
}

interface IResponseData {
	destinos: IResponseDataDestino[];
};

interface IResponseDataDestino {
	id: number;
	data_inicio: string;
	data_fim: string;
	nome: string;
	quantidade_disponivel: number;
	created_at: string;
	updated_at: string;
}

interface IModalInfo {
	isOpen: boolean;
	title: string;
	message: string;
}

interface IPaginaMensagem {
	isOn: boolean;
	mensagem?: string;
}

function FormViagem() {
	const [formFieldValues, setFormFieldValues] = useState<any>({});
	const [destinos, setDestinos] = useState<IResponseDataDestino[]>();
	const [nrOcorrencia, setNrOcorrencia] = useState<string>();
	const [modalInfo, setModalInfo] = useState<IModalInfo>();
	const [paginaMensagem, setPaginaMensagem] = useState<IPaginaMensagem>({ isOn: false });
	const [cidadesOrigem, setCidadesOrigem] = useState<string[]>(['Selecione']);
	const [cidadesViajante, setCidadesViajante] = useState<string[]>(['Selecione']);

	const onChangeFormFieldValue = (fieldName: string, fieldValue: any) => {
		if ([
			FIELD_NAMES.VIAGEM_DT_PREFERENCIA,
			FIELD_NAMES.VIAGEM_DT_ALTERNATIVA_1,
			FIELD_NAMES.VIAGEM_DT_ALTERNATIVA_2,
		].includes(fieldName)) {
			const [ano, mes, dia] = fieldValue.split('-');
			if (Number(dia) > 25) {
				setFormFieldValues({ ...formFieldValues, [fieldName]: '' });
				setModalInfo({
					isOpen: true,
					title: 'Data inválida',
					message: 'Você precisa selecionar uma data até o dia 25.'
				});
				return;
			}
		} else if ([FIELD_NAMES.VIAGEM_ESTADO_ORIGEM, FIELD_NAMES.VIAJANTE_ESTADO].includes(fieldName)) {
			loadCidades(fieldName, fieldValue);
		}

		setFormFieldValues({ ...formFieldValues, [fieldName]: fieldValue });
	}

	const loadCidades = (fieldName: string, uf: string) => {
		fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios`)
			.then(response => response.json())
			.then((response: [{ nome: string }]) => {
				if (fieldName === FIELD_NAMES.VIAGEM_ESTADO_ORIGEM) {
					setCidadesOrigem(['', ...response.map(cidade => cidade.nome)]);
				} else if (fieldName === FIELD_NAMES.VIAJANTE_ESTADO) {
					setCidadesViajante(['', ...response.map(cidade => cidade.nome)]);
				}
			});
	}

	const onSubmit = async () => {
		const response = await fetch(`https://apiv2.diallink.com.br/promoform/${nrOcorrencia}`,
			{
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ ...formFieldValues }),
			});
		if (response.status === 200) {
			setPaginaMensagem({ isOn: true, mensagem: 'Obrigado. Seus dados foram enviados com sucesso!' });
		} else if (response.status === 410) {
			const resp = response.json() as unknown as { data: IResponseData };
			onChangeFormFieldValue(FIELD_NAMES.VIAGEM_DESTINO, '');
			setDestinos(resp.data.destinos);
			setModalInfo({
				isOpen: true,
				title: 'ERRO',
				message: 'Infelizmente o Destino escolhido não está mais disponivel. Por favor selecione outro Destino.'
			});
		} else if (response.status === 500) {
			setModalInfo({
				isOpen: true,
				title: 'ERRO',
				message: 'Ocorreu um erro no servidor. Por favor tente novamente mais tarde.'
			});
		} else if (response.status === 401) {
			setPaginaMensagem({ isOn: true, mensagem: 'Cadastro já efetuado!' })
		}
	}

	const closeModal = () => {
		setModalInfo({ ...modalInfo as IModalInfo, isOpen: false });
	}

	useEffect(() => {
		const ocorrencia = window.location.pathname.slice(1);
		if (!ocorrencia || ocorrencia.trim().length === 0) {
			alert('Você precisa informar um número de Ocorrência!')
			return ;
		}else{
			setNrOcorrencia(ocorrencia);
		}

	}, []);

	useEffect(() => {
		if (!nrOcorrencia || nrOcorrencia.trim().length === 0) {
			return ;
		}else{
			fetch(`https://apiv2.diallink.com.br/promoform/${nrOcorrencia}`)
				.then(response => {
					if (response.status !== 200) {
						alert('Parametros inválidos')
						return null;
					}
					return response.json()
				})
				.then((response: { data: IResponseData }) => {
					if (response !== null) {
						setDestinos(response.data.destinos);
					}
				})
				.catch((err) => alert(err.message));
		}}, [nrOcorrencia])

	return (
		<Container>
			<div style={{ display: destinos !== undefined && paginaMensagem.isOn === false ? 'block' : 'none' }}>
				<Modal show={modalInfo?.isOpen === true} onHide={() => closeModal()}>
					<Modal.Header closeButton>
						<Modal.Title>{modalInfo?.title}</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<p>{modalInfo?.message}</p>
					</Modal.Body>

					<Modal.Footer>
						<Button variant="primary" onClick={() => closeModal()}>Ok</Button>
					</Modal.Footer>
				</Modal>
				<Form onSubmit={(evt) => { evt.preventDefault(); onSubmit(); }}>
					<img src={ Topo }/>
					<h1>INFORMAÇÕES DO(A) VIAJANTE</h1>
					<p>* O preenchimento de todos os campos é obrigatório.</p>
					<Form.Group>
						<Form.Label>Nome Completo:</Form.Label>
						<Form.Control required={true} type="text" value={formFieldValues[FIELD_NAMES.VIAJANTE_NOME_COMPLETO]} onChange={evt => onChangeFormFieldValue(FIELD_NAMES.VIAJANTE_NOME_COMPLETO, evt.target.value)} />
					</Form.Group>
					<Form.Group>
						<Form.Label>Endereço:</Form.Label>
						<Form.Control required={true} type="text" value={formFieldValues[FIELD_NAMES.VIAJANTE_ENDERECO]} onChange={evt => onChangeFormFieldValue(FIELD_NAMES.VIAJANTE_ENDERECO, evt.target.value)} />
					</Form.Group>
					<Form.Group>
						<Form.Label>Estado onde reside:</Form.Label>
						<Form.Select required={true} value={formFieldValues[FIELD_NAMES.VIAJANTE_ESTADO]} onChange={evt => onChangeFormFieldValue(FIELD_NAMES.VIAJANTE_ESTADO, evt.target.value)} >
							{(UFS).map(uf => <option value={uf.sigla}>{uf.nome}</option>)}
						</Form.Select>
					</Form.Group>
					<Form.Group>
						<Form.Label>Cidade onde reside:</Form.Label>
						<Form.Select required={true} value={formFieldValues[FIELD_NAMES.VIAJANTE_CIDADE]} onChange={evt => onChangeFormFieldValue(FIELD_NAMES.VIAJANTE_CIDADE, evt.target.value)} >
							{(cidadesViajante).map(cidade => <option>{cidade}</option>)}
						</Form.Select>
					</Form.Group>
					<Form.Group>
						<Form.Label>CEP:</Form.Label>
						<Form.Control required={true} type="text" value={formFieldValues[FIELD_NAMES.VIAJANTE_CEP]} onChange={evt => onChangeFormFieldValue(FIELD_NAMES.VIAJANTE_CEP, evt.target.value)} />
					</Form.Group>
					<Form.Group>
						<Form.Label>Data de Nascimento:</Form.Label>
						<Form.Control required={true} type="date" value={formFieldValues[FIELD_NAMES.VIAJANTE_DT_NASCIMENTO]} onChange={evt => onChangeFormFieldValue(FIELD_NAMES.VIAJANTE_DT_NASCIMENTO, evt.target.value)} />
					</Form.Group>
					<Form.Group>
						<Form.Label>CPF:</Form.Label>
						<Form.Control required={true} type="text" value={formFieldValues[FIELD_NAMES.VIAJANTE_CPF]} onChange={evt => onChangeFormFieldValue(FIELD_NAMES.VIAJANTE_CPF, evt.target.value)} />
					</Form.Group>
					<Form.Group>
						<Form.Label>RG:</Form.Label>
						<Form.Control required={true} type="text" value={formFieldValues[FIELD_NAMES.VIAJANTE_RG]} onChange={evt => onChangeFormFieldValue(FIELD_NAMES.VIAJANTE_RG, evt.target.value)} />
					</Form.Group>
					<Form.Group>
						<Form.Label>Telefone Celular (com WhatsApp):</Form.Label>
						<Form.Control required={true} type="text" value={formFieldValues[FIELD_NAMES.VIAJANTE_NR_TELEFONE_CELULAR]} onChange={evt => onChangeFormFieldValue(FIELD_NAMES.VIAJANTE_NR_TELEFONE_CELULAR, evt.target.value)} />
					</Form.Group>
					<Form.Group>
						<Form.Label>E-mail:</Form.Label>
						<Form.Control required={true} type="email" value={formFieldValues[FIELD_NAMES.VIAJANTE_EMAIL]} onChange={evt => onChangeFormFieldValue(FIELD_NAMES.VIAJANTE_EMAIL, evt.target.value)} />
					</Form.Group>
					<Form.Group>
						<Form.Label>Possui alguma alergia ou restrição médica/ alimentar?:</Form.Label>
						<Form.Check required={true} type="radio" name="abc" label="Sim" onChange={() => onChangeFormFieldValue(FIELD_NAMES.VIAJANTE_POSSUI_ALERGIA_RESTRICAO_MEDICA_ALIMENTAR, "true")} />
						<Form.Check required={true} type="radio" name="abc" label="Não" onChange={() => onChangeFormFieldValue(FIELD_NAMES.VIAJANTE_POSSUI_ALERGIA_RESTRICAO_MEDICA_ALIMENTAR, "false")} />
					</Form.Group>
					<Form.Group>
						<Form.Label>Especifique:</Form.Label>
						<Form.Control type="text" value={formFieldValues[FIELD_NAMES.VIAJANTE_POSSUI_ALERGIA_RESTRICAO_MEDICA_ALIMENTAR_DESCRICAO]} onChange={evt => onChangeFormFieldValue(FIELD_NAMES.VIAJANTE_POSSUI_ALERGIA_RESTRICAO_MEDICA_ALIMENTAR_DESCRICAO, evt.target.value)} />
					</Form.Group>
					<h2>INFORMAÇÕES DO(A) ACOMPANHANTE</h2>
					<Form.Group>
						<Form.Label>Nome Completo:</Form.Label>
						<Form.Control type="text" value={formFieldValues[FIELD_NAMES.ACOMPANHANTE_NOME_COMPLETO]} onChange={evt => onChangeFormFieldValue(FIELD_NAMES.ACOMPANHANTE_NOME_COMPLETO, evt.target.value)} />
					</Form.Group>
					<Form.Group>
						<Form.Label>Data de Nascimento:</Form.Label>
						<Form.Control type="date" value={formFieldValues[FIELD_NAMES.ACOMPANHANTE_DT_NASCIMENTO]} onChange={evt => onChangeFormFieldValue(FIELD_NAMES.ACOMPANHANTE_DT_NASCIMENTO, evt.target.value)} />
					</Form.Group>
					<Form.Group>
						<Form.Label>CPF:</Form.Label>
						<Form.Control type="text" value={formFieldValues[FIELD_NAMES.ACOMPANHANTE_CPF]} onChange={evt => onChangeFormFieldValue(FIELD_NAMES.ACOMPANHANTE_CPF, evt.target.value)} />
					</Form.Group>
					<Form.Group>
						<Form.Label>RG:</Form.Label>
						<Form.Control type="text" value={formFieldValues[FIELD_NAMES.ACOMPANHANTE_RG]} onChange={evt => onChangeFormFieldValue(FIELD_NAMES.ACOMPANHANTE_RG, evt.target.value)} />
					</Form.Group>
					<Form.Group>
						<Form.Label>Possui alguma alergia ou restrição médica/ alimentar?:</Form.Label>
						<Form.Check type="radio" name={FIELD_NAMES.ACOMPANHANTE_POSSUI_ALERGIA_RESTRICAO_MEDICA_ALIMENTAR} label="Sim" onChange={() => onChangeFormFieldValue(FIELD_NAMES.ACOMPANHANTE_POSSUI_ALERGIA_RESTRICAO_MEDICA_ALIMENTAR, "true")} />
						<Form.Check type="radio" name={FIELD_NAMES.ACOMPANHANTE_POSSUI_ALERGIA_RESTRICAO_MEDICA_ALIMENTAR} label="Não" onChange={() => onChangeFormFieldValue(FIELD_NAMES.ACOMPANHANTE_POSSUI_ALERGIA_RESTRICAO_MEDICA_ALIMENTAR, "false")} />
					</Form.Group>
					<Form.Group>
						<Form.Label>Especifique:</Form.Label>
						<Form.Control type="text" value={formFieldValues[FIELD_NAMES.ACOMPANHANTE_POSSUI_ALERGIA_RESTRICAO_MEDICA_ALIMENTAR_DESCRICAO]} onChange={evt => onChangeFormFieldValue(FIELD_NAMES.ACOMPANHANTE_POSSUI_ALERGIA_RESTRICAO_MEDICA_ALIMENTAR_DESCRICAO, evt.target.value)} />
					</Form.Group>
					<h2>CONFIGURAÇÃO DO APARTAMENTO</h2>
					<Form.Group>
						<Form.Check required={true} type="radio" name={FIELD_NAMES.APARTAMENTO_CAMA} label="Cama Casal" onChange={() => onChangeFormFieldValue(FIELD_NAMES.APARTAMENTO_CAMA, "CAMA CASAL")} />
						<Form.Check required={true} type="radio" name={FIELD_NAMES.APARTAMENTO_CAMA} label="Cama Solteiro" onChange={() => onChangeFormFieldValue(FIELD_NAMES.APARTAMENTO_CAMA, "CAMA SOLTEIRO")} />
					</Form.Group>
					<h2>INFORMAÇÃO DA VIAGEM</h2>
					<Form.Group>
						<Form.Label>Estado de Origem da Viagem:</Form.Label>
						<Form.Select required={true} value={formFieldValues[FIELD_NAMES.VIAGEM_ESTADO_ORIGEM]} onChange={evt => onChangeFormFieldValue(FIELD_NAMES.VIAGEM_ESTADO_ORIGEM, evt.target.value)} >
							{(UFS).map(uf => <option value={uf.sigla}>{uf.nome}</option>)}
						</Form.Select>
					</Form.Group>
					<Form.Group>
						<Form.Label>Cidade de Origem da Viagem:</Form.Label>
						<Form.Select required={true} value={formFieldValues[FIELD_NAMES.VIAGEM_CIDADE_ORIGEM]} onChange={evt => onChangeFormFieldValue(FIELD_NAMES.VIAGEM_CIDADE_ORIGEM, evt.target.value)} >
							{(cidadesOrigem).map(cidade => <option>{cidade}</option>)}
						</Form.Select>
					</Form.Group>
					<Form.Group>
						<Form.Label>Destino Escolhido:  &nbsp; &nbsp; &nbsp; Preparamos  <a href="./Destinos.zip" target="blank" download >  este material </a> para te ajudar na escolha do destino de sua preferência.</Form.Label>
						<Form.Select required={true} id={FIELD_NAMES.VIAGEM_DESTINO} value={formFieldValues[FIELD_NAMES.VIAGEM_DESTINO]} onChange={evt => onChangeFormFieldValue(FIELD_NAMES.VIAGEM_DESTINO, evt.target.value)} >
							<option value=''>Selecione</option>
		{destinos?.map(destino => <option value={destino.id}>{destino.nome}</option>)}
	</Form.Select>
</Form.Group>
<Form.Group>
	<Form.Label>Data de Preferência <small>(Escolha a data até o dia 25)</small>:</Form.Label>
	<Form.Control required={true} min={"2022-05-01"} max={"2022-12-25"} type="date" value={formFieldValues[FIELD_NAMES.VIAGEM_DT_PREFERENCIA]} onChange={evt => onChangeFormFieldValue(FIELD_NAMES.VIAGEM_DT_PREFERENCIA, evt.target.value)} />
</Form.Group>
<Form.Group>
	<Form.Label>Data Alternativa 1:</Form.Label>
	<Form.Control required={true} min={"2022-05-01"} max={"2022-12-25"} type="date" value={formFieldValues[FIELD_NAMES.VIAGEM_DT_ALTERNATIVA_1]} onChange={evt => onChangeFormFieldValue(FIELD_NAMES.VIAGEM_DT_ALTERNATIVA_1, evt.target.value)} />
</Form.Group>
<Form.Group>
	<Form.Label>Data Alternativa 2:</Form.Label>
	<Form.Control required={true} min={"2022-05-01"} max={"2022-12-25"} type="date" value={formFieldValues[FIELD_NAMES.VIAGEM_DT_ALTERNATIVA_2]} onChange={evt => onChangeFormFieldValue(FIELD_NAMES.VIAGEM_DT_ALTERNATIVA_2, evt.target.value)} />
</Form.Group>
<p> Ao prosseguir com o preenchimento da sua Ficha de Inscrição, você está ciente que seus dados e de seu acompanhante serão utilizados pelo Itaú Unibanco S/A, para cadastro na Viagem de Incentivo Nacional Itaú 2022 e serão usados para fins exclusivos da viagem, pelo banco e pelos parceiros envolvidos na organização da viagem de acordo com os   <a href=" https://www.itau.com.br/privacidade/" target="_blank" >Termos de Uso e Política de Privacidade</a>.</p>
<Form.Group>
	<Button className="btn-large btn-space" variant="primary" type="submit">
		Reservar!
	</Button>
</Form.Group>
								</Form>
							</div>
							<div style={{ display: destinos !== undefined && paginaMensagem.isOn === true ? 'block' : 'none' }}>
								<PaginaMensagem mensagem={paginaMensagem?.mensagem as string} />
							</div>
						</Container>
					);
}

export default FormViagem;
